<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <!-- 顶部图片 -->
    <div class="header">{{ name }}</div>
    <div class="title">{{ description }}</div>
    <!-- 产品product -->
    <div class="product">
      <div class="product_title">APPLICATIONS</div>
      <div class="product_main">
        <div
          class="product_main_box"
          v-for="item in twoTypeList"
          :key="item.id"
          @click="automated(item.id)"
        >
          <img :src="item.url" alt="" />
          <div>{{ item.name }}</div>
          <span></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/breadcrumb/breadcrumb";
import api from "@/utils/api";
export default {
  mounted() {
    console.log(this.$route.query.id);
    if (this.$route.query.id) {
      this.applicationOneTypeInfo(this.$route.query.id);
      sessionStorage.setItem("oneTypeListId", this.$route.query.id);
    } else {
      this.applicationOneTypeInfo(sessionStorage.getItem("oneTypeListId"));
    }
  },
  data() {
    return {
      breadcrumbList: [],
      twoTypeList: [], //二级分类列表
      name: "", //分类名称
      description: "", //分类描述
    };
  },
  watch: {
    $route(to) {
      if (to.query.id) {
        let id = to.query.id;
        this.applicationOneTypeInfo(id);
        sessionStorage.setItem("oneTypeListId", id);
      }
    },
  },
  methods: {
    //自动扫描跳转
    automated(id) {
      let name = this.name;
      sessionStorage.setItem("oneTypeListName", name);
      this.$router.push({ path: "/automated", query: { id } });
    },
    //获取一级分类详情
    applicationOneTypeInfo(id) {
      api.applicationOneTypeInfo({ id }).then((res) => {
        let data = res.data;
        if (data.code == 200) {
          this.twoTypeList = data.data.twoTypeList;
          this.name = data.data.name;
          this.description = data.data.description;
          //面包屑导航
          this.breadcrumbList = [
            { path: "/", title: "HOME" },
            { path: "industries", title: "INDUSTRIES & APPLICATIONS" },
            { path: "", title: data.data.name },
          ];
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
  components: {
    Breadcrumb,
  },
};
</script>

<style lang="scss" scoped>
.header {
  background-image: url("../../assets/index/carousel.png");
  background-repeat: no-repeat;
  background-size: 100%;
  height: 186px;
  min-width: 1156px;
  font-size: 56px;
  font-family: Arial;
  font-weight: bold;
  color: #ffffff;
  line-height: 186px;
  text-align: center;
}
.title {
  margin: 33px auto 37px;
  width: 1136px;
  line-height: 24px;
  font-size: 16px;
  font-family: Arial;
  font-weight: normal;
  color: #5a5b5e;
}
.product {
  min-height: 350px;
  .product_title {
    text-align: center;
    height: 53px;
    padding: 40px 0 50px;
    font-size: 50px;
    font-family: Arial;
    font-weight: bold;
    color: #004a9c;
  }
  .product_main {
    width: 1156px;
    margin: 0 auto;

    display: flex;

    flex-wrap: wrap;
    .product_main_box {
      position: relative;
      height: 262px;
      width: 207px;
      margin-bottom: 30px;
      cursor: pointer;
      img {
        width: 207px;
        height: 202px;
        display: block;
      }
      div {
        width: 177px;
        height: 60px;
        background: #f0f2f7;
        text-align: center;
        font-size: 16px;
        font-family: Arial;
        font-weight: bold;
        color: #5a5b5e;
        line-height: 60px;

        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        padding: 0 15px;
      }
    }
    .product_main_box:hover {
      span {
        position: absolute;
        width: 207px;
        height: 202px;
        display: block;
        background: #172f66;
        left: 0;
        top: 0;
        opacity: 0.2;
      }
      div {
        color: #ffffff;
        background: #004a9c;
      }
    }
    .product_main_box:not(:nth-of-type(5n)) {
      margin-right: 30px;
    }
  }
}
</style>